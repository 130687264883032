import { inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { UtilCommon } from '../../utils/UtilCommon';
import { UtilComponent } from '../../utils/UtilComponent';
import { AppStateConstant } from '../../constants/AppState.constant';
import { APP_CONFIG } from 'app.config';
import { Events } from '../Events';
import { AppState } from '../AppState';
import { AmplifyCommonService } from '../services/AmplifyCommon.service';
import { Performance, PerformanceTrace, trace } from '@angular/fire/performance';
import { CloudWatchLoggerService } from '../../config/config-cloud-watch/cloud-watch-log.service';
import { toHttpErrorLog } from '../../config/config-cloud-watch/logs.util';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  isRefreshingToken = 0;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  APPLICATION_TYPE = 'application/json;charset:UTF-8';
  private performance: Performance = inject(Performance);

  constructor(private utilsCmp: UtilComponent,
    private _awsCloudWatchService: CloudWatchLoggerService,
    private appState: AppState, private amplifyCommonService: AmplifyCommonService,
    private _translocoService: TranslocoService) { }

  addToken(req: HttpRequest<any>): HttpRequest<any> {
    if (!this.validUserNameSession()) {
      return null;
    }
    let jwt_token: string = this.appState.getServerToken();
    let access_token: string = this.appState.getAccessToken();
    if (!jwt_token && location.href.indexOf('sign-document?accessKey=') > -1) {
      jwt_token = this.appState.tokenSignPage;
    }
    if (!req.headers.has('Authorization')) {
      if (jwt_token && access_token) {
        // req = req.clone({ headers: req.headers.set('authorization', APP_CONFIG.KEY_JWT + jwt_token) });
        req = req.clone({ setHeaders: { 'Authorization': APP_CONFIG.KEY_JWT + jwt_token, 'Accept': '*/*', 'X-AWS-Authorization': access_token, 'AppCode': APP_CONFIG.APP_CODE } });
      } else if (access_token) {
        req = req.clone({ setHeaders: { 'X-AWS-Authorization': access_token, 'Accept': '*/*', 'AppCode': APP_CONFIG.APP_CODE } });
      } else if (jwt_token) {
        req = req.clone({ setHeaders: { 'Authorization': APP_CONFIG.KEY_JWT + jwt_token, 'Accept': '*/*', 'AppCode': APP_CONFIG.APP_CODE } });
      }
    }
    // use FormData (Content-Type: 'multipart/form-data'), don't set Content-Type in headers
    if (req.body instanceof FormData) {
      return req;
    }
    if (!req.headers.has('Content-Type') && req.method !== 'get') {
      req = req.clone({ headers: req.headers.set('Content-Type', this.APPLICATION_TYPE) });
      // req = req.clone({ setHeaders: { 'Content-Type':  'application/json;charset:UTF-8', 'Accept': '*/*' } });
    }
    return req.clone();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const userInfo = this.appState.getAppState();
    let performanceInstance: PerformanceTrace;
    if (request.url.includes(APP_CONFIG.HOST_NAME)) {
      performanceInstance = trace(this.performance, request.url);
      // If your app have multiple platform
      performanceInstance.putAttribute('APP_NAME', APP_CONFIG.APP_NAME);
      if (userInfo?.fullName) {
        performanceInstance.putAttribute('UserName', userInfo?.fullName);
      }
      performanceInstance.start();
    }
    return next.handle(this.addToken(request))
      .pipe(
        finalize(() => {
          if (performanceInstance) {
            performanceInstance.stop();
          }
        }),
        catchError((error, ca) => {
          if (error instanceof HttpErrorResponse) {
            this._awsCloudWatchService.logError(toHttpErrorLog(error));
            switch ((<HttpErrorResponse>error).status) {
              case 400:
                return this.handle400Error(request, next, error);
              case 401:
                this.handle401Error(request, next, error);
                return throwError(() => error);
              // return this.handle401Error(request, next, error);
              case 500:
                return this.handle500Error(request, next, error);
              default:
                return throwError(() => error);
            }
          } else {
            return throwError(() => error);
          }
        })
      );
  }

  handle500Error(req: HttpRequest<any>, next: HttpHandler, error: any): any {
    return throwError(error);
  }

  handle400Error(req: HttpRequest<any>, next: HttpHandler, error: any): any {
    // Handle error 400
    const hash: string = location.hash;
    // sign-document
    if (req.url.includes('mcrevl/api/enveloperu/getDocRecipientFieldCurrentUser') ||
      req.url.includes('mcrevl/api/envelope/getByTaskId')
    ) {
      const errorMessage = error?.error?.validation?.messages;
      for (const eMess of errorMessage) {
        if (eMess === 'tasknotfound' || eMess === 'envelope.notfound') {
          return throwError(eMess);
        }
      }
    }
    if (hash.indexOf('sign-document/sign/') > -1 || hash.indexOf('sign-document/?accessKey=') > -1) {
      Events.publish('main:logout');
    }
    return throwError(error);
  }

  async handle401Error(req: HttpRequest<any>, next: HttpHandler, error: any) {
    try {
      const hash: string = location.hash;
      this.appState.setUrlCurrent(hash.toString().replace('#', ''));
      if (this.appState.checkRoutePassLogin(hash)) {
        let errorMessage = error?.error?.validation?.messages;
        if (!errorMessage) {
          errorMessage = error?.error?.detail;
          if (errorMessage && UtilCommon.isString(errorMessage)) {
            return throwError(error);
          }
        }
        for (const eMess of errorMessage) {
          if (eMess === 'tasknotfound' || eMess === 'envelope.notfound') {
            return throwError(error);
          }
        }
        return throwError(error);
      }
      const username: string = localStorage.getItem(AppStateConstant.userName);
      if (!username) {
        Events.publish('main:logout');
        return throwError(error);
      }

      const errDetail = error?.error?.detail;
      if (errDetail && errDetail === 'ERROR_SESSION_INVALID') {
        Events.publish('main:logout');
        this.utilsCmp.openSnackBar(this._translocoService.translate('GENERAL.ERROR_SESSION_INVALID'), 'error');
        return;
      }

      if (this.isRefreshingToken === 1) {
        return;
      }
      if (this.isRefreshingToken == -1) {
        Events.publish('main:logout');
        return throwError(error);
      }
      // chỉ nên reject 1 lần trong 1 session
      this.isRefreshingToken = 1;
      const refreshToken = await this.amplifyCommonService.refreshToken(this.appState);
      this.isRefreshingToken = -1;
      if (!refreshToken) {
        Events.publish('main:logout');
        this.isRefreshingToken = 0;
        return throwError(error);

      }
      location.reload();
    } catch (e) {
      this.isRefreshingToken = -1;
    }
  }

  private validUserNameSession(): boolean {
    if (!UtilCommon.checkUsernameValid() && window[AppStateConstant.userName]) {
      const username: string = window[AppStateConstant.userName].username;
      // this.utilComponent.generateDialogAlertWithCallBack(() => {
      //   this.events.publish('main:logout');
      // }, window[AppStateConstant.appState] = null), 'Thông báo', '', `Tài khoản ${username} đã bị đăng xuất khỏi hệ thống.
      // 	Vui lòng đăng nhập lại.`, 'Đồng ý',;
      this.utilsCmp.generateDialogAlertWithCallBack(() => {
        Events.publish('main:logout');
        window[AppStateConstant.userName] = null;
      }, `Tài khoản ${username} đã bị đăng xuất khỏi hệ thống.	Vui lòng đăng nhập lại.`, 'Thông báo');
      return false;
    }
    return true;
  }
}