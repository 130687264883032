import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfFileViewComponent } from './pdf-file-view.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [PdfFileViewComponent],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    PdfFileViewComponent
  ]
})
export class PdfFileViewModule { }
