import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslocoModule } from "@ngneat/transloco";
import { APP_CONFIG } from "app.config";
import { COMMON_CONSTANT } from "app/shared/common/constants/Common.constant";
import { SharedModule } from "app/shared/shared.module";
import { UserService } from "./user.service";
import { UserStore } from "./user.store";

export class USER_URL {
    static readonly CREATE_USER: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/create';
    static readonly SEARCH_USER: string = APP_CONFIG.MCR_US_PROFILE + '/userProfile/pivotPagingWithSecInfo';
    static readonly UPDATE_USER: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/update';
    static readonly UPDATE_RECORD_STATUS: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/disable';
    static readonly GET_BY_AGG_ID: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/getByAggId?aggId=';
    static readonly PIVOT_COUNT: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/pivotCountWithSecInfo';
    static readonly GET_ROLE_BY_AGGID: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/getRolesOfUser?aggId=';
    static readonly GET_ALL_ROLE: string = APP_CONFIG.MCR_SECMT_API + 'account/getRoles';
    static readonly ASSIGN_ROLE_USER: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/assignRoleOfUser';
    static readonly QUICK_SEARCH_USER: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/quickSearch';
    static readonly LINK_SETUP_PASSWORD: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/getUrlUserInvite';
    static readonly LINK_RESET_PASSWORD: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/getUrlResetPass';
    static readonly CURRENT_APP: string = APP_CONFIG.MCR_US_PROFILE + 'userProfile/getCurrentUserApp';
}
// tslint:disable-next-line: max-classes-per-file
export class USER {
    static readonly NAVIGATE_PAGE_CREATE: string = `${COMMON_CONSTANT.MENU_ORGANIZATION}/user/create`;
    static readonly NAVIGATE_PAGE_LIST: string = `${COMMON_CONSTANT.MENU_ORGANIZATION}/user`;
    static readonly NAVIGATE_PAGE_DETAIL: string = `${COMMON_CONSTANT.MENU_ORGANIZATION}/user/`;
    static readonly NAVIGATE_PAGE_AUTHORIZATION_DETAIL: string = `${COMMON_CONSTANT.MENU_ORGANIZATION}/user/authorization/`;
    static readonly NO_DATA: string = 'No data';
    static readonly EMAIL_EXISTED: string = 'EMAIL.EXISTED';
    static readonly EMP_CODE_NOT_FOUND: string = 'EMP_CODE.NOT_FOUND';
    static EMP_CODE_EXISTED: string = 'EMP_CODE.EXISTED';
    static readonly LINK_URL: string = `#/${COMMON_CONSTANT.MENU_ORGANIZATION}/user`;
    static readonly ERROR_ID_UNACTIVE: string = 'validation.user.unactive';
    static readonly EMAIL_SETUP_PASSWORD: string = "emailSetupPassword";
    static readonly EMAIL_RESET_PASSWORD: string = "emailResetPassword";
}

export const UserOrganizationModule = [
    SharedModule,
    TranslocoModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule
];

export function UserProvider(){
    return [UserService, UserStore];
}

