<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'"
        [navigation]="navigation?.default" [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <div class="flex items-center h-20 pt-6 px-8 justify-center" style="cursor: pointer;">
                <img class="w-8" [src]="_appState?.settingsApp?.LOGO_APP" alt="Logo image">
                <span style="padding-left: 4px; font-size: 18px; font-weight: bold;">
                    {{ 'WELCOME_FIRSTNAME' | transloco }}
                    {{ 'WELCOME_LASTNAME' | transloco }}
                </span>
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-12 sm:h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <ng-container *ngIf="!isScreenSmall">
            <div (click)="gotoPageHome()" class="flex items-center mx-2" style="cursor: pointer;">

                <div class="hidden lg:flex">
                    <img class="hidden dark:flex w-8" [src]="_appState?.settingsApp?.LOGO_APP">
                </div>
                <div style="width: 6rem; height: 2rem;"><img alt="iCRM Logo" [src]="'assets/images/logo/logo_appname.jpg'"></div>
                <!-- <img class="flex w-8" [src]="_appState?.settingsApp?.LOGO_APP">
                <span style="padding-left: 4px; font-size: 18px;
                font-weight: bold;" class="text-logo">
                 <span class="text-logo-secondary">{{ 'WELCOME_FIRSTNAME' | transloco }}</span>
                 <span class="text-logo"> {{ 'WELCOME_LASTNAME' | transloco }}</span>
                </span> -->
            </div>
            <div *ngFor="let menu of menus" (click)="gotoPage(menu)"  hover-class="active-item"
                class="pl-4 pr-4 fuse-horizontal-navigation-item-title-wrapper cursor-pointer">
                <div  class="fuse-horizontal-navigation-item-title" style="padding: .5rem 0rem">
                    <span class="text-lg" [ngClass]="{'menu-active-parent': _navigationService.itemActive.title == menu.code}" >
                        {{menu.title  | transloco }}
                    </span>
                </div>
                <div [ngClass]="{'menu-active-child': _navigationService.itemActive.title == menu.code}"></div>
            </div>
            <!-- <div (click)="gotoPage('apps/envelope/inbox/1')"  hover-class="active-item"
                class="pl-4 pr-4 fuse-horizontal-navigation-item-title-wrapper cursor-pointer">
                <div  class="fuse-horizontal-navigation-item-title" style="padding: .5rem 0rem">
                    <span class="text-lg" [ngClass]="{'menu-active-parent': _navigationService.itemActive.title == 'MANAGE'}" >
                        {{ 'MENU.MANAGE' | transloco }}
                    </span>
                </div>
                <div [ngClass]="{'menu-active-child': _navigationService.itemActive.title == 'MANAGE'}"></div>
            </div>
            <div (click)="gotoPage('template/envelope-category')" hover-class="active-item" 
                class=" pl-4 pr-4 fuse-horizontal-navigation-item-title-wrapper cursor-pointer">
                <div  class="fuse-horizontal-navigation-item-title" style="padding: .5rem 0rem">
                    <span class="text-lg"
                        [ngClass]="{'menu-active-parent': _navigationService.itemActive.title == 'TEMPLATE'}">
                        {{ 'MENU.TEMPLATE' | transloco }}
                    </span>
                </div>
                <div [ngClass]="{'menu-active-child': _navigationService.itemActive.title == 'TEMPLATE'}"></div>
            </div>
            <div (click)="gotoPage('general/units')" hover-class="active-item" 
                class=" pl-4 pr-4 fuse-horizontal-navigation-item-title-wrapper  cursor-pointer">
                <div class="fuse-horizontal-navigation-item-title" style="padding: .5rem 0rem"
                    [ngClass]="{'menu-active-parent': _navigationService.itemActive.title == 'GENERAL'}">
                    <span class="text-lg">
                        {{ 'MENU.GENERAL' | transloco }}
                    </span>
                </div>
                <div [ngClass]="{'menu-active-child': _navigationService.itemActive.title == 'GENERAL'}"></div>
            </div>
            <div hover-class="active-item" class=" pl-4 pr-4 fuse-horizontal-navigation-item-title-wrapper cursor-pointer">
                <div class="fuse-horizontal-navigation-item-title" style="padding: .5rem 0rem">
                    <span class="text-lg" [ngClass]="{'menu-active-parent': _navigationService.itemActive.title == 'REPORT'}">
                        {{ 'MENU.REPORT' | transloco }}
                    </span>
                </div>
                <div [ngClass]="{'menu-active-child': _navigationService.itemActive.title == 'REPORT'}"></div>
            </div>
            <div (click)="gotoPage('settings/profile')" hover-class="active-item" 
                class=" pl-4 pr-4 fuse-horizontal-navigation-item-title-wrapper  cursor-pointer">
                <div  class="fuse-horizontal-navigation-item-title" style="padding: .5rem 0rem">
                    <span class="text-lg" [ngClass]="{'menu-active-parent': _navigationService.itemActive.title == 'SETTING'}">
                        {{ 'MENU.SETTING' | transloco }}
                    </span>
                </div>
                <div [ngClass]="{'menu-active-child': _navigationService.itemActive.title == 'SETTING'}"></div>
            </div> -->

        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <search [appearance]="'bar'"></search> -->
            <user></user>
            <!-- <button
            mat-icon-button
            (click)="openHelpPage()"
            [matTooltip]="'SETTINGS.HELP_PAGE' | transloco">
            <mat-icon  style="height: 30px; width: 30px;"
                class="text-current"
                [svgIcon]="'heroicons_solid:question-mark-circle'"></mat-icon>
            </button> -->
            <notifications></notifications>
            <languages></languages>
            <!-- <div *ngIf="isMultiApp" class="toolbar-separator" fxShow.gt-xs></div>
            <button *ngIf="isMultiApp" mat-icon-button class="navbar-toggle-button" (click)="openApp()">
                <mat-icon *ngIf="!showMultiApp" class="secondary-text">apps</mat-icon>
                <mat-icon *ngIf="showMultiApp" class="secondary-text">close</mat-icon>
            </button> -->
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <div [style.visibility]=" showMultiApp === true ? 'visible' : 'hidden'" style="overflow: hidden;
    top: 0px;
    transition: height 0.1s ease 0s;
    width: 350px;
    z-index: 991;
    position: absolute;
    height: 501px;
    margin-top: 57px;
    max-height: calc(-65px + 100vh);
    right: 0px;
    margin-right: 0px;
    visibility: hidden;" class="multiple-app">
        <div style="justify-content: center; display: flex;">
            <ul class="LVal7b card">
                <li *ngFor="let app of apps" class="j1ei8c">
                    <a (click)="closeMultiApp()" class="tX9u1b" [href]="app.link" target="_blank"><span class="MrEfLc">
                            <img [src]="app.icon">
                        </span><span class="Rq5Gcb">{{app.appName}}</span></a>
                </li>
            </ul>
        </div>
    </div>
</div>