import { Component, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { AppState } from 'app/shared/common/utils/AppState';
import { DmsService } from 'app/shared/common/utils/DmsService';
import { Events } from 'app/shared/common/utils/Events';
import { UtilComponent } from 'app/shared/common/utils/UtilComponent';

@Component({
    selector: 'app-pdf-file-view',
    templateUrl: './pdf-file-view.component.html',
    styleUrls: ['./pdf-file-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfFileViewComponent implements OnDestroy {

    showIframe: any;
    _previewUrl: any;
    constructor(public appState: AppState,
        private changeDetectorRef: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private _dmsService: DmsService,
        private _utilComponent: UtilComponent) {
        Events.subscribe('event:viewPdfFile', (data: any) => {
            setTimeout(() => {
                this.viewPdf(data);
            }, 0);
        });
    }

    viewPdf(data): void {
        let file = data.file;
        let type = data.type;
        if (type === 'ENVELOPE') {
            this._dmsService.getPdfMultiPartContentEnvelop(file.identifier).then(result => {
                let blob = result;
                const urlCreator = window.URL || window['webkitURL'];
                const src = urlCreator.createObjectURL(blob);
                setTimeout(() => {
                    this.showIframe = true;
                    this.changeDetectorRef.detectChanges();
                }, 300);
                this._previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(src);
            }).catch((error) => {
                console.error('Can\'t not get resources', error);
                this._utilComponent.showTranslateSnackbar('FILE_NOT_FOUND', 'error');
            });
        } else if (type === 'LOWCODER') {
            this._dmsService.getMultiPartContentLowcoder(file.identifier).then(result => {
                let blob = result;
                const urlCreator = window.URL || window['webkitURL'];
                const src = urlCreator.createObjectURL(blob);
                setTimeout(() => {
                    this.showIframe = true;
                    this.changeDetectorRef.detectChanges();
                }, 300);
                this._previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(src);
            }).catch((error) => {
                console.error('Can\'t not get resources', error);
                this._utilComponent.showTranslateSnackbar('FILE_NOT_FOUND', 'error');
            });
        } else {
            this._dmsService.getMultiPartContent(file.identifier).then(result => {
                let blob = result;
                const urlCreator = window.URL || window['webkitURL'];
                const src = urlCreator.createObjectURL(blob);
                setTimeout(() => {
                    this.showIframe = true;
                    this.changeDetectorRef.detectChanges();
                }, 300);
                this._previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(src);
            }).catch((error) => {
                console.error('Can\'t not get resources', error);
                this._utilComponent.showTranslateSnackbar('FILE_NOT_FOUND', 'error');
            });
        }
    }

    ngOnDestroy(): void {
        this.showIframe = false;
        this._previewUrl = '';
    }
}
