import { APP_CONFIG } from "app.config";

export class NotificationConstant {
    static GET_VERSION_APP: string = APP_CONFIG.MCRNOTIFICATION +
        'mcrnotification/services/appAttbService/getMobileVersionInfo';
    static MARK_READ: string =
        APP_CONFIG.MCRNOTIFICATION + 'api/notification/updateRead';
    static READ_ALL =
        APP_CONFIG.MCRNOTIFICATION + 'api/notification/readAll';
    static GET_COUNT_UNREAD: string =
        APP_CONFIG.MCRNOTIFICATION + 'api/notification/countUnread';
    static SAVE_UPDATE_DEVICE: string =
        APP_CONFIG.MCRNOTIFICATION + 'api/userDevice/save';
    static DELETE_USER_DEVICE: string =
        APP_CONFIG.MCRNOTIFICATION + 'api/userDevice/delete';
    static GET_PAGING_LIST_NOTIFICATIONS: string =
        APP_CONFIG.MCRNOTIFICATION + 'api/notification/getPagingList';
    static GET_PAGING_LIST: string =
        APP_CONFIG.MCRNOTIFICATION + 'api/notification/getPagingListSummary';
    static GET_DETAIL_NOTIFICATION: string =
        APP_CONFIG.MCRNOTIFICATION + 'api/notification/getByAggId?aggId=';
    static DELETE_NOTIFICATION: string =
        APP_CONFIG.MCRNOTIFICATION + 'api/notification/delete';
    static PIVOT_COUNT: string =
        APP_CONFIG.MCRNOTIFICATION + 'api/notification/countPagingList';

    static UNREAD: number = 1;
    static READ: number = 0;
    static LIMIT: number = 10;
}
